<template>
  <div v-if="influencer">
    <v-container fluid class="p-0">
      <v-layout wrap align-center justify-center>
        <v-flex md4>
          <influencer-channel-select
            v-if="influencer.channels.length > 1"
            v-model="selectedInfluencerChannel"
            :items="influencer.channels"
          ></influencer-channel-select>
        </v-flex>
        <v-flex md8 class="text-right">
          <v-btn
            v-if="!isManaged"
            color="primary"
            class="hidden-xs-only"
            @click="showCampaignInvitationDialog = true"
            >Invite to a campaign</v-btn
          >
          <v-btn
            v-if="!isManaged"
            color="primary"
            class="hidden-sm-and-up"
            fixed
            bottom
            right
            fab
            @click="showCampaignInvitationDialog = true"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <bulk-invite-campaign-dialog
      v-model="showCampaignInvitationDialog"
      :selectedInfluencer="influencer"
    ></bulk-invite-campaign-dialog>

    <v-card class="mt-3">
      <v-card-title class="card-title">
        <div>
          <div>{{ influencer.user.full_name }}</div>
          <div class="body-2 light-grey">
            {{ influencer.birthday | date_to_age }} year /
            {{ influencer.gender === "MALE" ? "Male" : "Female" }}
          </div>
        </div>
        <div class="card-favorite cursor-pointer pb-4" v-if="isBusiness">
          <favorite-influencer
            :influencer="influencer"
            @change="updateFavorite"
          ></favorite-influencer>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout wrap class="mb-4">
          <v-flex md4 class="text-center align-self-center">
            <influencer-channel-avatar
              :user="influencer.user"
              :channel="selectedInfluencerChannel"
              outlined
            ></influencer-channel-avatar>

            <v-btn
              v-if="selectedInfluencerChannel"
              color="primary"
              class="mt-3"
              outlined
              :href="
                `${selectedChannelWebsite}${selectedInfluencerChannel.username}`
              "
              target="_blank"
              :block="$vuetify.breakpoint.xsOnly"
            >
              See {{ selectedInfluencerChannel.channel.display_name }} profile
            </v-btn>
          </v-flex>
          <v-flex md8 xs12>
            <v-container
              v-if="selectedInfluencerChannel.categories.length > 0"
              grid-list-md
              class="p-0"
            >
              <v-layout wrap>
                <v-flex
                  v-for="category in selectedInfluencerChannel.categories"
                  :key="category.id"
                >
                  <v-card flat class="flex-card bordered" height="100%">
                    <v-card-text class="text-center pb-0 pt-2 grow">
                      <v-icon medium>{{ category.icon }}</v-icon>

                      <div class="mt-2">
                        {{ category.display_name }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <influencer-channel-stats-container
      class="mt-4"
      :influencer-channel="selectedInfluencerChannel"
    ></influencer-channel-stats-container>
  </div>
</template>

<script>
import channel_color from "@/helpers/filters/channel_color";
import date_to_age from "@/helpers/filters/date_to_age";
import first_letter from "@/helpers/filters/first_letter";
import InfluencerChannelSelect from "../../selects/InfluencerChannelSelect";
import { mapActions, mapGetters, mapState } from "vuex";
import InfluencerChannelStatsContainer from "../../containers/InfluencerChannelStatsContainer";
import InfluencerChannelAvatar from "../../avatars/InfluencerChannelAvatar";
import FavoriteInfluencer from "../../inputs/FavoriteInfluencer";
import BulkInviteCampaignDialog from "../dialogs/BulkInviteCampaignDialog";

export default {
  filters: {
    channel_color,
    date_to_age,
    first_letter
  },
  components: {
    BulkInviteCampaignDialog,
    InfluencerChannelAvatar,
    InfluencerChannelStatsContainer,
    InfluencerChannelSelect,
    FavoriteInfluencer
  },
  data: () => ({
    fab: false,
    influencer: null,
    selectedInfluencerChannel: null,
    stats: null,
    showCampaignInvitationDialog: false
  }),
  computed: {
    ...mapGetters("core/auth", ["isManaged", "isBusiness"]),
    ...mapState("core/auth", ["user"]),
    avatarSize() {
      if (this.$vuetify.breakpoint.sm) {
        return 150;
      } else if (this.$vuetify.breakpoint.xs) {
        return 100;
      }

      return 200;
    },
    followers() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.followers,
        0
      );
    },
    impressions() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.impressions,
        0
      );
    },
    followerGrowthData() {
      let stats = this.stats.daily.slice(0, 13);
      let data = {
        dates: [],
        values: []
      };

      stats.forEach(value => {
        data.dates.push(value.date);
        data.values.push(value.followers);
      });

      data.dates.reverse();
      data.values.reverse();

      return data;
    },
    selectedChannelWebsite() {
      return this.selectedInfluencerChannel.channel.name === 'tiktok' ? 'https://tiktok.com/@' : 'https://instagram.com/';
    }
  },
  watch: {
    // Update influencer(page) if route param changes
    $route() {
      this.getInfluencerData();
    },
    selectedInfluencerChannel: function() {
      this.fetchStats();
    }
  },
  methods: {
    ...mapActions("core", ["getInfluencer", "getInfluencerStats"]),
    getInfluencerData() {
      this.influencer = null;
      const params = {
        uuid: this.$route.params.id
      };
      this.getInfluencer(params).then(
        response => {
          this.influencer = response;
          this.selectedInfluencerChannel = response.channels[0];
          if (this.isBusiness) {
            this.getFavoriteStatus();
          }
        },
        error => {
          this.setSnackError("Something went wrong");
          console.log(error);
        }
      );
    },
    getFavoriteStatus() {
      this.influencer.is_favorite = false;
      this.influencer.favorite_by_business.map(business => {
        if (business.uuid === this.user.business.uuid) {
          this.influencer.is_favorite = true;
          this.$forceUpdate();
        }
      });
    },
    updateFavorite(event) {
      this.influencer.is_favorite = event;
    },
    fetchStats() {
      const params = {
        influencer_channel_uuid: this.selectedInfluencerChannel.uuid
      };
      this.getInfluencerStats(params).then(
        response => {
          this.stats = response;
        },
        error => {
          this.setSnackError("Something went wrong");
          console.log(error);
        }
      );
    }
  },
  created() {
    this.getInfluencerData();
  }
};
</script>
<style lang="scss" scoped>
.card-title {
  display: flex;
  justify-content: space-between;
}
</style>
